/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { JSONMap as e } from "../../core/jsonMap.js";
const i = new e({
  esriFieldTypeSmallInteger: "small-integer",
  esriFieldTypeInteger: "integer",
  esriFieldTypeSingle: "single",
  esriFieldTypeDouble: "double",
  esriFieldTypeLong: "long",
  esriFieldTypeString: "string",
  esriFieldTypeDate: "date",
  esriFieldTypeOID: "oid",
  esriFieldTypeGeometry: "geometry",
  esriFieldTypeBlob: "blob",
  esriFieldTypeRaster: "raster",
  esriFieldTypeGUID: "guid",
  esriFieldTypeGlobalID: "global-id",
  esriFieldTypeXML: "xml",
  esriFieldTypeBigInteger: "big-integer",
  esriFieldTypeDateOnly: "date-only",
  esriFieldTypeTimeOnly: "time-only",
  esriFieldTypeTimestampOffset: "timestamp-offset"
});
export { i as kebabDict };